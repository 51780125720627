<!--精选榜单-->
<template>
  <div class="container">
    <div class="top-theme">
      <div class="theme1">
        <span>众惠精选</span>
        <span v-if="themeTitle">&nbsp;· {{ themeTitle }}</span>
      </div>
      <div class="theme2">{{ themeDescribe }}</div>
    </div>
    <div class="content">
      <div v-for="(item,index) in list" :key="item.fundCode+index" class="list">
        <router-link :to="{name:'FundDetail',params:{fundCode:item.fundCode}}">
          <div class="title flex">
            <div class="code-num">{{ item.fundCode||'--' }}</div>
            <div class="name">{{ item.fundName||'--' }}</div>
          </div>
        </router-link>
        <div class="flex fund-content">
          <div class="left flex">
            <div class="item">
              <div class="top-text">{{ risklevelDic[item.ofundRisklevel]||'--' }}</div>
              <div class="bottom-text">风险等级</div>
            </div>
            <div class="item">
              <div class="top-text worth">{{ item.newestValue||'--' }}</div>
              <div class="bottom-text">{{ item.enddate||'--' }}</div>
            </div>
            <div class="item">
              <div
                class="top-text"
                :class="{'red':item.changeDay>0,'green':item.changeDay<0}"
              >{{ item.changeDay?formatDecimal(accMul(item.changeDay,1),2):'--' }}%</div>
              <div class="bottom-text">日涨跌幅</div>
            </div>
            <div class="item">
              <div
                class="top-text"
                :class="{'red':item.changeMonth>0,'green':item.changeMonth<0}"
              >{{ item.changeMonth?formatDecimal(accMul(item.changeMonth,1),2):'--' }}%</div>
              <div class="bottom-text">近1月</div>
            </div>
            <div class="item">
              <div
                class="top-text"
                :class="{'red':item.changeThreeMonth>0,'green':item.changeThreeMonth<0}"
              >{{ item.changeThreeMonth?formatDecimal(accMul(item.changeThreeMonth,1),2):'--' }}%</div>
              <div class="bottom-text">近3月</div>
            </div>
            <div class="item">
              <div
                class="top-text"
                :class="{'red':item.changeHalfYear>0,'green':item.changeHalfYear<0}"
              >{{ item.changeHalfYear?formatDecimal(accMul(item.changeHalfYear,1),2):'--' }}%</div>
              <div class="bottom-text">近半年</div>
            </div>
            <div class="item">
              <div
                class="top-text"
                :class="{'red':item.changeYear>0,'green':item.changeYear<0}"
              >{{ item.changeYear?formatDecimal(accMul(item.changeYear,1),2):'--' }}%</div>
              <div class="bottom-text">近1年</div>
            </div>
            <div class="item" v-if="false">
              <div v-if="item.saleFeeRate&&item.saleFeeRate==0" class="no-rate">免手续费</div>
              <div
                v-else
                class="top-text"
              >{{ item.saleFeeRate?formatDecimal(accMul(item.saleFeeRate,100),2) :'--' }}%</div>
              <div class="bottom-text">买入费率</div>
            </div>
          </div>
          <div class="right flex">
            <router-link :to="{name:'ProcessPurchase',params:{fundCode:item.fundCode}}">
              <div class="common buy">购买</div>
            </router-link>
            <router-link :to="{name:'AddProcess',params:{fundCode:item.fundCode}}">
              <div class="common sale">定投</div>
            </router-link>
          </div>
        </div>
      </div>
      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="pageNum"
        :limit.sync="pageSize"
        :layout="layout"
        @pagination="getList"
      />
    </div>
  </div>
</template>


<script>
import { accMul, formatDecimal } from '@/utils/debounce.js'
import { achievementLead, getChannalAndTag } from '@/api/achievementLead'
export default {
  name: 'AchievementLead',
  components: {},
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 20,
      layout: 'prev, pager, next, sizes',
      //状态分类(潜力指数,业绩领先,海外分红) tagCode 5,6,7
      tagCode: '',
      // 三个主题
      channelList: [],
      list: [],
      // 风险等级字典
      risklevelDic: {},
      // 主题
      themeTitle: '',
      // 主题描述
      themeDescribe: ''
    }
  },
  created() {
    const { tagCode, type } = this.$route.query
    this.tagCode = tagCode
    this.getList(type)
    // 风险等级
    this.getDictionaries('sys_fund_risklevel', 'risklevelDic')
    // 查询推荐渠道
    this.getTag()
  },
  methods: {
    accMul,
    formatDecimal,
    //字典查询
    getDictionaries(val, data) {
      this.getDicts(val).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            this[data][item.dictValue] = item.dictLabel
          })
        }
      })
    },
    getList(val) {
      let key = ''
      if (val == 1) {
        key = 'customerWebOneTag'
      } else if (val == 2) {
        key = 'customerWebTwoTag'
      } else if (val == 3) {
        key = 'customerWebThreeTag'
      }
      achievementLead({
        changeDay: '-1',
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        [key]: '1'
        // customerAppOneTopTag: 1
      })
        .then((res) => {
          if (res.code === 200) {
            const { data } = res
            this.list = data.data
            this.total = data.total
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    // 获取推荐渠道
    getTag() {
      getChannalAndTag()
        .then((res) => {
          if (res.code === 200) {
            // 将获取的主题与路由传入的名称对应
            res.data.forEach((r) => {
              if (r.tagCode == this.tagCode) {
                this.themeTitle = r.tagName
                this.themeDescribe = r.tagDescribe
              }
            })
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }
}
</script>


<style lang="scss" scoped>
.container {
  background: #f3f4f6 url('~@/static/img/selected-list/bg.svg') no-repeat;
  background-size: 100%;
}

// 顶部主题
.top-theme {
  padding-top: 43px;
  padding-bottom: 35px;
  text-align: center;

  .theme1 {
    font-weight: bold;
    font-size: 36px;
    line-height: 36px;

    span:nth-child(1) {
      color: #bd5450;
    }
    span:nth-child(2) {
      color: #b49768;
    }
  }
  .theme2 {
    margin-top: 21px;
    font-size: 24px;
    line-height: 24px;
    color: #bdc0cb;
  }
}
.content {
  width: 1200px;
  // padding-top: 167px;
  margin: 0 auto;
  padding-bottom: 40px;
}

/* 列表项 */
.list {
  margin-top: 8px;
  padding: 36px 30px 31px;
  background: #fff;
  box-shadow: 0px 4px 12px #edf1f7;
  // 内容文字包裹
  .name {
    text-align: center;
  }
  .top-text {
    font-size: 16px;
    line-height: 16px;
    color: #a5a5a5;
  }
  .no-rate {
    font-size: 16px;
    line-height: 16px;
    color: #c8c6c0;
  }

  .worth {
    color: #25293d;
  }

  .red {
    color: #c5231c;
  }
  .green {
    color: #01b834;
  }
  .bottom-text {
    margin-top: 5px;
    font-weight: 290;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #25293d;
  }

  // 基金标题
  .title {
    margin-bottom: 24px;
    cursor: pointer;

    .code-num {
      font-weight: 300;
      font-size: 18px;
      line-height: 18px;
      color: #ce9b63;
    }
    .name {
      margin-left: 14px;
      font-weight: 290;
      font-size: 20px;
      line-height: 20px;
      color: #1f1f1f;
    }
  }

  // 基金内容
  .fund-content {
    justify-content: space-between;
    .common {
      padding: 7px 50px;
      border-radius: 2px;
      font-size: 14px;
      line-height: 15px;
      text-align: center;
      cursor: pointer;
    }
    // 购买
    .buy {
      color: #25293d;
      border: 0.85px solid #e0e2e8;
      box-sizing: border-box;
      border-radius: 2px;
    }
    .buy:hover {
      color: #fff;
      background: #deb87b;
    }
    // 定投
    .sale {
      margin-left: 14px;
      background: #ffffff;
      border: 0.68px solid #ce9b63;
      color: #b88141;
    }
    .sale:hover {
      color: #fff;
      background: #deb87b;
    }

    // 左侧内容
    .left {
      flex: 1;
    }

    .right {
      margin-left: 83px;
    }
    .item {
      flex: 1;
      text-align: center;
    }
  }
}
</style>
